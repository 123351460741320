<template>
  <aside class="hidden w-96 border-l border-gray-200 overflow-y-auto lg:block py-6 sm:px-6 lg:px-8">
    <TransitionRoot
        appear
        :show="!loadingStats"
        enter="transition-opacity duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <form action="#" method="GET" @submit.prevent="addDevice">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">{{ info.urzadzenie.obu }}</h3>
                <p class="mt-1 text-sm text-gray-500">{{ info.urzadzenie.nazwa }} - status danych OBU</p>
              </div>

              <PanelInfoStats v-for="(statData, index) in statsData" :key="index" :index="index" :statData="statData"/>

            </div>
          </div>
        </form>
      </div>

    </TransitionRoot>
  </aside>
</template>

<script>
import {ref} from 'vue'
import {TransitionRoot} from "@headlessui/vue";
import {ref as fbRef, onValue, off} from 'firebase/database';

import app from '@/firebase';
import PanelInfoStats from "@/components/PanelStatsInfo";

let refStatsData = null;

export default {
  name: "PanelStats",
  components: {
    TransitionRoot,
    PanelInfoStats
  },
  props: ['info'],
  setup() {

    const loadingStats = ref(true);

    return {
      loadingStats
    }
  },
  watch: {
    'info.ident': function () {
      this.getStatsData();
    }
  },
  data() {
    return {
      statsData: {}
    }
  },
  mounted() {
    this.getStatsData();
  },
  unmounted() {
    off(refStatsData);
  },
  methods: {
    getStatsData() {
      this.loadingStats = true;
      if (refStatsData) {
        off(refStatsData);
      }
      refStatsData = fbRef(app.database, `urzadzenie/${this.info.ident}/stats/odczyt`);
      onValue(refStatsData, data => {
        if (data.val()) {
          const now_60minutes = Date.now() - 3600000;
          const statsData = Object.values(data.val());
          this.statsData = statsData.sort((a, b) => a.ws < b.ws).flatMap((value, index, array) => {
            if (value.ws >= now_60minutes) {
              return [value];
            } else {
              const firstElement = array[0];
              if (firstElement) {
                if (value.ws >= firstElement.ws - 3600000) {
                  return [value];
                }
              }
            }
            return [];
          });
        } else {
          this.statsData = [];
        }
        this.loadingStats = false;
      })
    }
  }
}
</script>

<style scoped>

</style>