<template>

  <!-- Main content -->
  <div class="flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
      <!-- Primary column -->
      <section aria-labelledby="primary-heading"
               class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
        <div class="py-6 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="pb-5 sm:flex sm:items-center sm:justify-between">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Panel diagnostyczny OBU
            </h3>
          </div>
          <h1 id="primary-heading" class="sr-only">Panel diagnostyczny OBU</h1>
          <TransitionRoot
              appear
              :show="isUserUid"
              enter="transition-opacity duration-750"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="transition-opacity duration-1000"
              leave-from="opacity-100"
              leave-to="opacity-0"
          >
            <PanelUrzadzenia :user="user"></PanelUrzadzenia>
          </TransitionRoot>
        </div>
      </section>
    </main>

    <!-- Secondary column (hidden on smaller screens) -->
    <PanelStats v-if="panelStats" :info="sharedState.deviceInfo"></PanelStats>
    <PanelETOLL v-if="panelETOLL" :info="sharedState.deviceInfo"></PanelETOLL>
    <PanelSENT v-if="panelSENT" :info="sharedState.deviceInfo"></PanelSENT>
    <PanelSaldo v-if="panelSaldo" :info="sharedState.deviceInfo"></PanelSaldo>
    <PanelAlert v-if="panelAlert" :info="sharedState.deviceInfo"></PanelAlert>
  </div>


</template>

<script>

import {ref as fbRef, onValue, off} from 'firebase/database';
import {TransitionRoot} from "@headlessui/vue";

import app from '@/firebase';
import store from '@/store';
import PanelUrzadzenia from '@/components/PanelUrzadzenia'
import PanelStats from "@/components/PanelStats";
import PanelETOLL from "@/components/PanelETOLL";
import PanelAlert from "@/components/PanelAlert";
import PanelSaldo from "@/components/PanelSaldo";
import PanelSENT from "@/components/PanelSENT";

export default {
  name: 'Panel',
  components: {
    TransitionRoot,
    PanelSaldo,
    PanelUrzadzenia,
    PanelAlert,
    PanelETOLL,
    PanelSENT,
    PanelStats
  },
  setup() {
    const refStatus = fbRef(app.database, '.info/connected');

    return {
      refStatus
    }
  },
  computed: {
    isUserUid() {
      return this.user != null && this.user.uid !== '';
    },
    isAnonymous() {
      return this.user != null ? this.user.isAnonymous : false;
    },
    panelStats() {
      return this.sharedState.deviceInfo && this.sharedState.deviceInfo.data === 'stats';
    },
    panelETOLL() {
      return this.sharedState.deviceInfo && this.sharedState.deviceInfo.data === 'etoll';
    },
    panelSENT() {
      return this.sharedState.deviceInfo && this.sharedState.deviceInfo.data === 'sent';
    },
    panelSaldo() {
      return this.sharedState.deviceInfo && this.sharedState.deviceInfo.data === 'saldo';
    },
    panelAlert() {
      return this.sharedState.deviceInfo && this.sharedState.deviceInfo.data === 'alert';
    }
  },
  data() {
    return {
      sharedState: store.state,
      user: app.auth.currentUser
    }
  },
  mounted() {
    this.getStatus();
  },
  unmounted() {
    off(this.refStatus);
  },
  methods: {
    getStatus() {
      onValue(this.refStatus, data => {
        this.status = data.val();
      });
    }
  }
}
</script>
