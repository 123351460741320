import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
import {getFunctions} from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAotFqEP8joJo5xrUsH_r1jsCEHgkmwInI",
  authDomain: "geobox-app-78bba.firebaseapp.com",
  databaseURL: "https://geobox-app-78bba-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "geobox-app-78bba",
  storageBucket: "geobox-app-78bba.appspot.com",
  messagingSenderId: "541197174680",
  appId: "1:541197174680:web:bd43a66f1137f8b794cded",
  measurementId: "G-Y55XMWNEEP"
};
const firebaseApp = initializeApp(firebaseConfig);

const app = {
  geobox: null,
  database: getDatabase(firebaseApp),
  auth: getAuth(firebaseApp),
  functions: getFunctions(firebaseApp, 'europe-west1')
}

export default app;
