<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <button type="button" class="text-sm text-gray-900">
        {{ urzadzenie.obu }}
      </button>
      <div class="text-xs text-gray-500">{{ urzadzenie.nazwa }}</div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <a href="#" @click.prevent="setDeviceInfo({data: 'stats', ident: urzadzenie.ident, urzadzenie: urzadzenie})"
              class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"
              :class="status.class">
        {{ status.opis }}
      </a>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <a href="#" @click.prevent="setDeviceInfo({data: 'etoll', ident: urzadzenie.ident, urzadzenie: urzadzenie})"
              class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"
              :class="etoll.class">
        {{ etoll.opis }}
      </a>
    </td>
<!--    <td class="px-6 py-4 whitespace-nowrap">-->
<!--      <a href="#" @click.prevent="setDeviceInfo({data: 'saldo', ident: urzadzenie.ident, urzadzenie: urzadzenie})"-->
<!--              class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"-->
<!--              :class="saldo.class">-->
<!--        {{ saldo.opis }}-->
<!--      </a>-->
<!--    </td>-->
    <td class="px-6 py-4 whitespace-nowrap">
      <a href="#" @click.prevent="setDeviceInfo({data: 'sent', ident: urzadzenie.ident, urzadzenie: urzadzenie})"
              class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"
              :class="sent.class">
        {{ sent.opis }}
      </a>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <a href="#" @click.prevent="setDeviceInfo({data: 'alert', ident: urzadzenie.ident, urzadzenie: urzadzenie})"
              class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"
              :class="alert.class">
        {{ alert.opis }}
      </a>
    </td>
  </tr>
</template>

<script>
import {ref} from 'vue';
import {ref as fbRef, onValue, off} from 'firebase/database';

import app from '@/firebase';
import data from '@/const';
import store from '@/store';

export default {
  name: 'PanelUrzadzenie',
  props: ['urzadzenie', 'ts'],
  setup(props) {

    const loadingCheck = ref(true);

    const refCheckData = fbRef(app.database, `urzadzenie/${props.urzadzenie.ident}/check`);

    const statusDesc = data.statusDesc;
    const saldoDesc = data.saldoDesc;
    const alertDesc = data.alertDesc;
    const etollAlertDesc = data.etollAlertDesc;
    const sentAlertDesc = data.sentAlertDesc;
    const odczytCheckDesc = data.odczytCheckDesc;
    const etollCheckDesc = data.etollCheckDesc;
    const sentCheckDesc = data.sentCheckDesc;

    return {
      statusDesc,
      saldoDesc,
      alertDesc,
      etollAlertDesc,
      sentAlertDesc,
      odczytCheckDesc,
      etollCheckDesc,
      sentCheckDesc,
      loadingCheck,
      refCheckData
    }
  },
  data() {
    return {
      sharedState: store.state,
      checkData: {}
    }
  },
  mounted() {
    this.getCheckData();
  },
  unmounted() {
    off(this.refCheckData);
  },
  computed: {
    status() {

      if (!this.checkData || !this.checkData.odczyt || !this.checkData.heartbeat) {
        return this.statusDesc['UNKNOWN'];
      }

      const now = Math.max(Date.now(), this.ts);
      const min_5 = 5 * 60 * 1000;
      const now_5 = now - min_5;
      const now_10 = now - 2 * min_5;
      const heartbeat = this.checkData.heartbeat;
      const odczyt = this.checkData.odczyt;

      if (odczyt.ign === data.IGNITION_ON && odczyt.cc === data.POLAND) {
        if (odczyt.tmax >= now_5) {
          return this.statusDesc['ACTIVE'];
        } else if (odczyt.tmax >= now_10) {
          return this.statusDesc['WARNING'];
        } else {
          return this.statusDesc['ALARM'];
        }
      }

      if (odczyt.ign === data.IGNITION_ON && odczyt.cc === data.POLAND_OUTSIDE) {
        if (odczyt.tmax >= now_5) {
          return this.statusDesc['ACTIVE'];
        } else {
          return this.statusDesc['POLAND_OUTSIDE'];
        }
      }

      if (odczyt.ign === data.IGNITION_OFF && odczyt.cc === data.POLAND) {
        if (heartbeat.tmax >= now_10) {
          return this.statusDesc['INACTIVE'];
        } else {
          return this.statusDesc['PLUGOUT'];
        }
      }

      if (odczyt.ign === data.IGNITION_OFF && odczyt.cc === data.POLAND_OUTSIDE) {
        if (heartbeat.tmax >= now_10) {
          return this.statusDesc['INACTIVE'];
        } else {
          return this.statusDesc['POLAND_OUTSIDE'];
        }
      }

      return this.statusDesc['UNKNOWN'];

    },
    etoll() {

      if (!this.checkData || !this.checkData.etoll || !this.checkData.heartbeat) {
        return this.statusDesc['UNKNOWN'];
      }

      const now = Math.max(Date.now(), this.ts);
      const min_5 = 5 * 60 * 1000;
      const now_5 = now - min_5;
      const now_10 = now - 2 * min_5;
      const heartbeat = this.checkData.heartbeat;
      const etoll = this.checkData.etoll;

      if (etoll.ign === data.IGNITION_ON && etoll.cc === data.POLAND) {
        if (etoll.tmax >= now_5) {
          return this.statusDesc['ACTIVE'];
        } else if (etoll.tmax >= now_10) {
          return this.statusDesc['WARNING'];
        } else {
          return this.statusDesc['ALARM'];
        }
      }

      if (etoll.ign === data.IGNITION_ON && etoll.cc === data.POLAND_OUTSIDE) {
        if (etoll.tmax >= now_5) {
          return this.statusDesc['ACTIVE'];
        } else {
          return this.statusDesc['POLAND_OUTSIDE'];
        }
      }

      if (etoll.ign === data.IGNITION_OFF && etoll.cc === data.POLAND) {
        if (heartbeat.tmax >= now_10) {
          return this.statusDesc['INACTIVE'];
        } else {
          return this.statusDesc['PLUGOUT'];
        }
      }

      if (etoll.ign === data.IGNITION_OFF && etoll.cc === data.POLAND_OUTSIDE) {
        if (heartbeat.tmax >= now_10) {
          return this.statusDesc['INACTIVE'];
        } else {
          return this.statusDesc['POLAND_OUTSIDE'];
        }
      }

      return this.statusDesc['UNKNOWN'];

    },
    saldo() {
      if (!this.checkData || !this.checkData.saldo) {
        return this.saldoDesc['UNKNOWN'];
      }

      if (this.checkData.saldo.pr){
        const desc = this.saldoDesc[this.checkData.saldo.pr.toUpperCase()] || this.saldoDesc['UNKNOWN'];
        return {
          stan: desc.stan,
          opis: `${this.checkData.saldo.balance} zł`,
          class: desc.class
        }
      }
      return this.saldoDesc['UNKNOWN'];
    },
    sent() {

      if (!this.checkData || !this.checkData.sent || !this.checkData.heartbeat) {
        return this.statusDesc['UNKNOWN'];
      }

      const now = Math.max(Date.now(), this.ts);
      const min_5 = 5 * 60 * 1000;
      const now_5 = now - min_5;
      const now_10 = now - 2 * min_5;
      const heartbeat = this.checkData.heartbeat;
      const sent = this.checkData.sent;

      if (sent.ign === data.IGNITION_ON && sent.cc === data.POLAND) {
        if (sent.tmax >= now_5) {
          return this.statusDesc['ACTIVE'];
        } else if (sent.tmax >= now_10) {
          return this.statusDesc['WARNING'];
        } else {
          return this.statusDesc['ALARM'];
        }
      }

      if (sent.ign === data.IGNITION_ON && sent.cc === data.POLAND_OUTSIDE) {
        if (sent.tmax >= now_5) {
          return this.statusDesc['ACTIVE'];
        } else {
          return this.statusDesc['POLAND_OUTSIDE'];
        }
      }

      if (sent.ign === data.IGNITION_OFF && sent.cc === data.POLAND) {
        if (heartbeat.tmax >= now_10) {
          return this.statusDesc['INACTIVE'];
        } else {
          return this.statusDesc['PLUGOUT'];
        }
      }

      if (sent.ign === data.IGNITION_OFF && sent.cc === data.POLAND_OUTSIDE) {
        if (heartbeat.tmax >= now_10) {
          return this.statusDesc['INACTIVE'];
        } else {
          return this.statusDesc['POLAND_OUTSIDE'];
        }
      }

      return this.statusDesc['UNKNOWN'];

    },
    alert() {

      const alerty = [];

      if (!this.checkData || !this.checkData.alert) {
        return this.alertDesc['UNKNOWN'];
      }
      const alert = this.checkData.alert;

      if (alert.rc + alert.ec + alert.sc +
          alert.rw + alert.ew + alert.sw === 0) {
        return this.alertDesc['EMPTY'];
      }
      if (alert.rw > 0) {
        const alertDesc = this.odczytCheckDesc[`${alert.rw}`] || this.odczytCheckDesc['UNKNOWN'];
        alerty.push(alertDesc);
      }
      if (alert.ew > 0) {
        const alertDesc = this.etollCheckDesc[`${alert.ew}`] || this.etollCheckDesc['UNKNOWN'];
        alerty.push(alertDesc);
      }
      if (alert.sw > 0) {
        const alertDesc = this.sentCheckDesc[`${alert.sw}`] || this.sentCheckDesc['UNKNOWN'];
        alerty.push(alertDesc);
      }

      if (alert.rc > 0) {
        for(const idx of [1, 2, 3, 4]) {
          const digit = this.getDigit(alert.rc, idx);
          if (digit > 0) {
            const code =  Math.pow(10, idx - 1);
            const alertDesc = this.alertDesc[`${code}`] || this.alertDesc['UNKNOWN'];
            alerty.push(alertDesc);
          }
        }
      }
      if (alert.ec > 0) {
        for(const idx of [1, 2, 3]) {
          const digit = this.getDigit(alert.ec, idx);
          if (digit > 0) {
            const code =  Math.pow(10, idx - 1);
            const alertDesc = this.etollAlertDesc[`${code}`] || this.etollAlertDesc['UNKNOWN'];
            alerty.push(alertDesc);
          }
        }
      }
      if (alert.sc > 0) {
        for(const idx of [1, 2, 3]) {
          const digit = this.getDigit(alert.sc, idx);
          if (digit > 0) {
            const code =  Math.pow(10, idx - 1);
            const alertDesc = this.sentAlertDesc[`${code}`] || this.sentAlertDesc['UNKNOWN'];
            alerty.push(alertDesc);
          }
        }
      }
      if (alerty.length > 0) {
        console.log(alerty);
        const dangers = alerty.filter(val => val.priorytet === data.DANGER);
        const warnings = alerty.filter(val => val.priorytet === data.WARNING);
        if (dangers.length + warnings.length === 1) {
          if (dangers.length === 1) {
            return dangers[0];
          } else {
            return warnings[0];
          }
        } else {
          if (dangers.length > 0) {
            const danger = dangers[0];
            return {
              priorytet: danger.priorytet,
              opis: `${danger.opis} oraz ${dangers.length + warnings.length - 1} innych zdarzeń`,
              class: danger.class
            };
          } else if (warnings.length > 0) {
            const warning = warnings[0];
            return {
              priorytet: warning.priorytet,
              opis: `${warning.opis} oraz ${warnings.length - 1} innych zdarzeń`,
              class: warning.class
            };
          } else {
            return this.alertDesc['UNKNOWN'];
          }
        }
      }
      return this.alertDesc['EMPTY'];
    }
  },
  methods: {
    getCheckData() {
      this.loadingCheck = true;
      onValue(this.refCheckData, data => {
        this.checkData = data.val();
        this.loadingCheck = false;
      });
    },
    setDeviceInfo(data) {
      store.setDeviceInfo(data);
    },
    setDeviceAlert(data) {
      store.setDeviceAlert(data);
    },
    getDigit(number, n) {
      return Math.floor((number / Math.pow(10, n - 1)) % 10);
    }
  }
}
</script>

<style scoped>

</style>