import {onAuthStateChanged} from 'firebase/auth';
import {createApp} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';

import '@/index.css';
import app from '@/firebase';

import App from '@/App.vue';
import Strona from '@/components/Strona.vue';
import Login from '@/components/Login';
import Mapa from '@/components/Mapa';
import Panel from '@/components/Panel';
import OBU from '@/components/OBU'
import Ustawienia from '@/components/Ustawienia'

const routes = [
  {
    path: '/',
    component: Strona,
    children: [
      {
        path: '',
        component: Panel
      },
      {
        path: 'mapa',
        component: Mapa
      },
      {
        path: 'panel',
        component: Panel
      },
      {
        path: 'obu',
        component: OBU
      },
      {
        path: 'ustawienia',
        component: Ustawienia
      },
    ]
  },
  {
    path: '/login',
    component: Login
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(function (to, from, next) {
  if ((to.path !== '/login' && to.path !== 'login') && !app.auth.currentUser) {
    next({path: '/login', replace: true})
  } else if ((to.path === '/login' || to.path === 'login') && app.auth.currentUser) {
    next({path: '/'})
  } else {
    next()
  }
});

onAuthStateChanged(app.auth, () => {
  console.log(`onAuthStateChanged`);
  if (!app.geobox) {
    app.geobox = createApp(App);
    app.geobox.use(router);
    app.geobox.mount('#app');
  }
});


