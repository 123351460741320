<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div v-if="!signin" class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
           alt="Workflow">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Zaloguj się
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600 max-w">
        Na Twój adres email wyślemy link do logowania
      </p>
    </div>

    <div v-if="!signin" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" method="post" action="" @submit.prevent="loginWithEmail">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Adres email
            </label>
            <div class="mt-1">
              <input id="email" name="email" type="email" autocomplete="email" v-model="email" required
                     class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>

          <div>
            <button type="submit"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Zaloguj się
            </button>
          </div>
        </form>

      </div>
    </div>

    <div v-if="signin && !email" class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
           alt="Workflow">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Weryfikacja adresu email
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600 max-w">
        Wprowadadź email w celu weryfikacji logowania
      </p>
    </div>

    <div v-if="signin && !email" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" method="post" action="" @submit.prevent="signinWithEmail(this.email)">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Adres email
            </label>
            <div class="mt-1">
              <input id="email_ver" name="email" type="email" autocomplete="email" v-model="email" required
                     class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>

          <div>
            <button type="submit"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Weryfikuj
            </button>
          </div>
        </form>

      </div>
    </div>

    <div v-if="showModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
         aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <transition
          enter-active-class="transition ease-out duration-300 transform"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-200 transform"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-show="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
               aria-hidden="true"></div>
        </transition>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <transition
          enter-active-class="transition ease-out duration-300 transform"
          enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="transition ease-in duration-200"
          leave-from-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <!-- Heroicon name: outline/check -->
                <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Wysłano link
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Zaloguj się do panelu klikając w link logowania w otrzymanej wiadomości email.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </transition>

      </div>
    </div>

  </div>
</template>

<script>
import {ref} from 'vue'
import {sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';
import app from "@/firebase";

export default {
  name: 'Login',
  props: {},
  data: () => ({
    email: ""
  }),
  setup() {
    const showModal = ref(false);
    const signin = ref(isSignInWithEmailLink(app.auth, window.location.href));
    const email = window.localStorage.getItem('emailForSignIn');

    return {
      showModal,
      signin,
      email
    }
  },
  mounted() {
    if (this.signin && this.email) {
        this.signinWithEmail(this.email);
    }
  },
  methods: {
    loginWithEmail() {
      let actionCodeSettings = {
        url: 'https://panel.geobox.app/login',
        handleCodeInApp: true,
        iOS: {
          bundleId: 'com.geobox.ios'
        },
        android: {
          packageName: 'com.geobox.android',
          installApp: true,
          minimumVersion: '14'
        },
        dynamicLinkDomain: 'geobox.page.link'
      };

      sendSignInLinkToEmail(app.auth, this.email, actionCodeSettings)
        .then(() => {
          console.log("Wyslano");
          window.localStorage.setItem('emailForSignIn', this.email);
          this.showModal = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    signinWithEmail(email) {
      signInWithEmailLink(app.auth, email, window.location.href)
        .then((result) => {
          console.log(JSON.stringify(result));
          window.localStorage.removeItem('emailForSignIn');
          console.log(this.$route.path);
          this.$router.replace('/');
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    }
  }
}
</script>
