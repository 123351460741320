<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <a href="#" @click.prevent="updateDevice" class="text-sm text-gray-900">
        {{ urzadzenie.obu }}
      </a>
      <div class="text-xs text-gray-500">{{ urzadzenie.nazwa }}</div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
        <a href="#" @click.prevent="getPIN" class="inline-flex text-sm text-gray-900 text-left">
          <span v-if="!loadingPin" :class="{'filter blur-sm' : pin === PIN_PATTERN}">{{ pin }}</span>
          <svg v-if="loadingPin" class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25 stroke-current text-indigo-600" cx="12" cy="12" r="10" stroke-width="4"></circle>
            <path class="opacity-75 fill-current text-indigo-600" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </a>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"
          :class="etollStatus.class"
      >
        {{ etollStatus.opis }}
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"
           :class="sentStatus.class"
      >
        {{ sentStatus.opis }}
      </div>
    </td>
<!--    <td class="px-6 py-4 whitespace-nowrap">-->
<!--      <div class="px-2 inline-flex text-xs leading-5 font-medium rounded-full"-->
<!--           :class="alertyStatus.class"-->
<!--      >-->
<!--        {{ alertyStatus.opis }}-->
<!--      </div>-->
<!--    </td>-->
  </tr>
</template>

<script>
import {ref} from 'vue';
import {httpsCallable} from 'firebase/functions';

import app from '@/firebase';
import store from '@/store';

const PIN_PATTERN = 'XXXX';

export default {
  name: "OBUUrzadzenie",
  props: ['urzadzenie'],
  setup() {
    const pin = ref(PIN_PATTERN);
    const loadingPin = ref(false);

    const statusDesc = {
      'AKTYWNY': {
        opis: 'Aktywny',
        class: {
          'text-green-800': true,
          'bg-green-100': true,
          'hover:bg-green-200': true
        }
      },
      'NIEAKTYWNY': {
        opis: 'Niekatywny',
        class: {
          'text-gray-800': true,
          'bg-gray-100': true,
          'hover:bg-gray-200': true
        }
      }
    };

    return {
      statusDesc,
      loadingPin,
      pin
    }
  },
  computed: {
    etollStatus() {
      if (this.urzadzenie.etoll) {
        return this.statusDesc['AKTYWNY'];
      } else {
        return this.statusDesc['NIEAKTYWNY'];
      }
    },
    sentStatus() {
      if (this.urzadzenie.sent) {
        return this.statusDesc['AKTYWNY'];
      } else {
        return this.statusDesc['NIEAKTYWNY'];
      }
    },
    alertyStatus() {
      if (this.urzadzenie.alerty) {
        return this.statusDesc['AKTYWNY'];
      } else {
        return this.statusDesc['NIEAKTYWNY'];
      }
    },
  },
  methods: {
    updateDevice() {
      store.setDevice(this.urzadzenie);
      store.setOpDevice('UPDATE');
    },
    getPIN() {
      if (this.pin === PIN_PATTERN){
        this.loadingPin = true;
        const getPIN = httpsCallable(app.functions, 'getPIN');
        getPIN({ident: this.urzadzenie.ident})
            .then((result) => {
              this.loadingPin = false;
              const data = result.data;
              if (data.status === 'OK' && data.ident === this.urzadzenie.ident && data.obu === this.urzadzenie.obu) {
                this.pin = data.pin;
              } else if (data.status === 'OK' && (data.ident !== this.urzadzenie.ident || data.obu !== this.urzadzenie.obu)) {
                console.log(data.status);
              } else {
                console.log(data.status);
              }
            });
      } else {
        this.pin = PIN_PATTERN;
      }
    }
  },
  created() {
    this.PIN_PATTERN = PIN_PATTERN;
  }
}
</script>

<style scoped>

</style>