<template>
  <aside class="hidden w-96 border-l border-gray-200 overflow-y-auto lg:block py-6 sm:px-6 lg:px-8">
    <TransitionRoot
        appear
        :show="!loadingETOLL"
        enter="transition-opacity duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <form action="#" method="GET" @submit.prevent="addDevice">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">{{ info.urzadzenie.obu }}</h3>
                <p class="mt-1 text-sm text-gray-500">{{ info.urzadzenie.nazwa }} - status danych e-TOLL</p>
              </div>

              <PanelETOLLInfo v-for="(eTOLLData, index) in eTOLLData" :key="index" :index="index" :eTOLLData="eTOLLData"/>

            </div>
          </div>
        </form>
      </div>

    </TransitionRoot>
  </aside>
</template>

<script>
import {ref} from 'vue'
import {TransitionRoot} from "@headlessui/vue";
import {ref as fbRef, onValue, off} from 'firebase/database';

import app from '@/firebase';
import PanelETOLLInfo from "@/components/PanelETOLLInfo";

let refETOLLData = null;

export default {
  name: 'PanelETOLL',
  components: {
    TransitionRoot,
    PanelETOLLInfo
  },
  props: ['info'],
  setup() {

    const loadingETOLL = ref(true);

    return {
      loadingETOLL
    }
  },
  watch: {
    'info.ident': function () {
      this.getETOLLData();
    }
  },
  data() {
    return {
      eTOLLData: {}
    }
  },
  mounted() {
    this.getETOLLData();
  },
  unmounted() {
    off(refETOLLData);
  },
  methods: {
    getETOLLData() {
      this.loadingETOLL = true;
      if (refETOLLData) {
        off(refETOLLData);
      }
      refETOLLData = fbRef(app.database, `urzadzenie/${this.info.ident}/stats/etoll`);
      onValue(refETOLLData, data => {
        if (data.val()) {
          const now_60minutes = Date.now() - 3600000;
          const eTOLLData = Object.values(data.val());
          this.eTOLLData = eTOLLData.sort((a, b) => a.ws < b.ws).flatMap((value, index, array) => {
            if (value.ws >= now_60minutes) {
              return [value];
            } else {
              const firstElement = array[0];
              if (firstElement) {
                if (value.ws >= firstElement.ws - 3600000) {
                  return [value];
                }
              }
            }
            return [];
          });
        } else {
          this.eTOLLData = [];
        }
        this.loadingETOLL = false;
      })
    }
  }

}

</script>

<style scoped>

</style>