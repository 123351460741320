<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <div class="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="h-8 w-auto"
                 src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                 alt="Geobox"/>
            <h2 class="text-2xl font-bold leading-7 ml-2 text-gray-900 sm:text-2xl sm:truncate">
              Geobox
            </h2>
          </div>
          <div class="mt-5 flex-grow flex flex-col">
            <nav class="flex-1 px-2 bg-white space-y-1">
              <router-link custom v-for="item in navigation" :key="item.name" :to="item.href"
                           v-slot="{isActive, href, navigate}">
                <a :href="href" @click="navigate"
                   :class="[isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                  <component :is="item.icon"
                             :class="[isActive ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 h-6 w-6']"
                             aria-hidden="true"/>
                  {{ item.name }}
                </a>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" static class="md:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
                           enter-to="opacity-100" leave="transition-opacity ease-linear duration-300"
                           leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
          </TransitionChild>
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                           enter-from="-translate-x-full" enter-to="translate-x-0"
                           leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                           leave-to="-translate-x-full">
            <div class="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                               enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100"
                               leave-to="opacity-0">
                <div class="absolute top-1 right-0 -mr-14 p-1">
                  <button type="button"
                          class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                          @click="mobileMenuOpen = false">
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                    <span class="sr-only">Zamknij</span>
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 px-4 flex items-center">

              </div>
              <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                <nav class="h-full flex flex-col">
                  <div class="space-y-1">
                    <router-link custom v-for="item in navigation" :key="item.name" :to="item.href"
                                 v-slot="{isActive, href, navigate}">
                      <a :href="href" @click="navigate"
                         :class="[isActive ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white', 'group py-2 px-3 rounded-md flex items-center text-sm font-medium']"
                         :aria-current="isActive ? 'page' : undefined">
                        <component :is="item.icon"
                                   :class="[isActive ? 'text-white' : 'text-indigo-300 group-hover:text-white', 'mr-3 h-6 w-6']"
                                   aria-hidden="true"/>
                        <span>{{ item.name }}</span>
                      </a>
                    </router-link>
                  </div>
                </nav>
              </div>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <header class="w-full">
        <div class="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
          <button type="button"
                  class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                  @click="mobileMenuOpen = true">
            <span class="sr-only">Otwórz panel</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true"/>
          </button>
          <div class="flex-1 flex justify-between px-4 sm:px-6">
            <div class="flex-1 flex">
              <form class="w-full flex md:ml-0" action="#" method="GET" @submit.prevent="setFilter">
                <label for="search_field" class="sr-only">Szukaj urządzeń</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <SearchIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true"/>
                  </div>
                  <input name="search_field" id="search_field" v-model="search"
                         class="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400"
                         placeholder="Szukaj" type="search"/>
                </div>
              </form>
            </div>
            <div class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
              <!-- Profile dropdown -->
              <Menu as="div" class="relative flex-shrink-0">
                <div>
                  <MenuButton
                    class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Otwórz menu użytkownika</span>
                    <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                        <span class="text-sm font-medium leading-none text-white">{{initials}}</span>
                      </span>
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <router-link custom to="Ustawienia"
                                 v-slot="{href, navigate}">
                      <MenuItem v-slot="{ active }">
                        <a :href="href" @click="navigate"
                           :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                          Ustawienia
                        </a>
                      </MenuItem>
                    </router-link>
                      <MenuItem v-slot="{ active }">
                        <a href="#" @click.prevent="logout"
                           :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                          Wyloguj
                        </a>
                      </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>

      <!-- Content area -->
      <router-view></router-view>

    </div>

  </div>
</template>

<script>

import {ref} from 'vue'
import {signOut} from 'firebase/auth';

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogOverlay
} from '@headlessui/vue'
import {
  DeviceMobileIcon,
  TableIcon,
  LocationMarkerIcon,
  XIcon,
  MenuAlt2Icon,
  PlusIcon
} from '@heroicons/vue/outline'
import {SearchIcon} from '@heroicons/vue/solid'

const navigation = [
  {name: 'Mapa', href: '/mapa', icon: LocationMarkerIcon},
  {name: 'Panel diagnostyczny OBU', href: '/panel', icon: TableIcon},
  {name: 'Urządzenia OBU', href: '/obu', icon: DeviceMobileIcon}
]

const userNavigation = [
  {name: 'Ustawienia', href: '/ustawienia'},
  {name: 'Wyloguj', href: '/wyloguj'}
]

import app from "@/firebase";
import store from '@/store';

export default {
  name: 'Strona',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Dialog,
    DialogOverlay,
    XIcon,
    MenuAlt2Icon,
    PlusIcon,
    SearchIcon
  },
  setup() {
    const mobileMenuOpen = ref(false);
    return {
      mobileMenuOpen,
      userNavigation,
      navigation
    }
  },
  data() {
    return {
      sharedState: store.state,
      user: app.auth.currentUser,
      search: ''
    }
  },
  computed: {
    initials() {
      return this.user.email.charAt(0).toUpperCase();
    }
  },
  methods: {
    setFilter(){
      console.log(this.search);
      store.setSearch(this.search);
    },
    logout() {
      if (!this.user || !this.user.uid) {
        console.log(`Brak zalogowanego użytkownika`);
        this.$router.push('/login');
      } else {
        console.log(`Wyloguj użytkownika => ${this.user.uid}`);
        signOut(app.auth)
          .then(() => {
            this.$router.push('/login');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
}
</script>