<template>

  <div class="flex-1 flex items-stretch overflow-hidden">
    <!-- Secondary column (hidden on smaller screens) -->
    <aside class="w-96 bg-white border-r border-gray-200 overflow-y-auto">
      <TransitionRoot
        appear
        :show="urzadzenia.length > 0"
        enter="transition-opacity duration-1000"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-1000"
        leave-from="opacity-100"
        leave-to="opacity-0"
        class="overflow-y-auto h-full"
      >
        <ul class="divide-y divide-gray-200">
          <MapaUrzadzenie v-for="urzadzenie in filtrUrzadzen" :urzadzenie="urzadzenie" :key="urzadzenie.ident" :selected="sharedState.record === urzadzenie.ident" :ts="ts"/>
        </ul>
      </TransitionRoot>
    </aside>
    <main class="flex-1 relative">
      <div class="w-full h-full">
        <div id="map" class="w-full h-full"></div>
      </div>
      <div v-if="false" class="absolute left-6 top-6 w-96 bg-white shadow border-b border-gray-200 sm:rounded-md">
        {{ record }}
      </div>
    </main>
  </div>

</template>

<script>

import mapboxgl from 'mapbox-gl';
import {ref} from 'vue';
import {TransitionRoot} from "@headlessui/vue";
import {ref as fbRef, get} from 'firebase/database';

import app from '@/firebase';
import store from '@/store';
import MapaUrzadzenie from "@/components/MapaUrzadzenie";

export default {
  name: 'Mapa',
  components: {MapaUrzadzenie, TransitionRoot},
  setup() {

    const loading = ref(true);
    const refUrzadzenia = fbRef(app.database, `uzytkownik/${app.auth.currentUser.uid}/urzadzenie`);
    const layer = {
      'id': 'points',
      'type': 'circle',
      'source': 'points',
      'filter': ['!', ['has', 'point_count']],
      'paint': {
        'circle-color': [
          'match',
          ['get', 'ign'],
          'true',
          '#0099ff',
          'false',
          '#ff0000', '#14b829'],
        'circle-radius': 6,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      }
    };
    const clusters = {
      'id': 'clusters',
      'type': 'circle',
      'source': 'points',
      'filter': ['has', 'point_count'],
      'paint': {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#E5E7EB',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          10,
          100,
          20,
          750,
          30
        ],
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      }
    }
    const clusterCount = {
      'id': 'cluster-count',
      'type': 'symbol',
      'source': 'points',
      'filter': ['has', 'point_count'],
      'layout': {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
        'text-allow-overlap': true,
      }
    };
    const points = {
      'type': 'FeatureCollection',
      'features': []
    };

    return {
      loading,
      refUrzadzenia,
      layer,
      points,
      clusters,
      clusterCount
    }
  },
  data() {
    return {
      sharedState: store.state,
      urzadzenia: [],
      map: {},
      popup: {},
      timeout: {},
      initTimeout: 2000,
      ts: Date.now()
    }
  },
  mounted() {
    this.createMap();
    this.getDevices();
    this.timer = setInterval(this.refresh, 60 * 1000);
  },
  unmounted() {
    this.clearMap();
    clearInterval(this.timer);
    clearInterval(this.timeout);
  },
  computed: {
    filtrUrzadzen() {
      if (!this.sharedState.search) {
        return this.urzadzenia;
      }
      const filter = value =>
        value.obu.toLowerCase().includes(this.sharedState.search.toLowerCase()) ||
        value.nazwa.toLowerCase().includes(this.sharedState.search.toLowerCase());
      return this.urzadzenia.filter(filter);
    },
    record(){
      return this.sharedState.records[this.sharedState.record];
    }
  },
  watch: {
    'sharedState.search': function () {
      store.refreshRecords();
      store.unselectRecord();
    },
    'sharedState.refreshRecords': function () {
      if (this.timeout)
        clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        if (!this.map.getSource('points')) {
          return;
        }
        const features = [];
        for (const prop in this.sharedState.records) {
          if (Object.prototype.hasOwnProperty.call(this.sharedState.records, prop)) {
            const record = this.sharedState.records[prop];
            const feature = {
              'type': 'Feature',
              'properties': {
                'ang': record.ang,
                'spd': record.spd,
                'sat': record.sat,
                'ign': parseInt(record.data['409']) === 1 ? 'true' : 'false',
                'acc': parseInt(record.data['29']),
                'ident': record.ident,
                'obu': record.obu,
                'nazwa': record.nazwa
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [record.lon, record.lat]
              }
            }
            features.push(feature);
          }
        }

        const points = {
          'type': 'FeatureCollection',
          'features': features
        };
        this.map.getSource('points').setData(points);
        this.initTimeout = 200;
      }, this.initTimeout);
    },
    'sharedState.record': function() {
      if (!this.sharedState.record) {
        return;
      }
      const record = this.sharedState.records[this.sharedState.record];
      if (!record) {
        return;
      }
      this.map.flyTo({
        center: [record.lon, record.lat],
      })
    }
  },
  methods: {
    createMap() {
      mapboxgl.accessToken = 'pk.eyJ1IjoibHMtZ2VvYm94IiwiYSI6ImNrcDJocGYwaTAxc2MybmxlMmVlaXlpNmUifQ.89nGW5SMimYHmNhIZMMrHA';
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        minZoom: 4,
        bounds: [[15, 49], [21, 55]],
        zoom: 16,
        attributionControl: false,
        fadeDuration: 50
      });
      this.map.addControl(new mapboxgl.NavigationControl());
      this.map.addControl(new mapboxgl.AttributionControl({
        compact: true
      }));
      this.popup = new mapboxgl.Popup({
        offset: 12,
        closeButton: false,
        closeOnClick: false
      });
      this.map.on('load', () => {
        const map = this.map;
        const popup = this.popup;
        map.addSource('points', {
          type: 'geojson',
          data: this.points,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 12
        });
        map.addLayer(this.layer);
        map.addLayer(this.clusters);
        map.addLayer(this.clusterCount);
        map.on('mouseenter', 'points', function (e) {
          map.getCanvas().style.cursor = 'pointer';
          const [feature] = e.features;
          const {properties: {obu, nazwa}, geometry: {coordinates}} = feature;
          popup.setLngLat(coordinates).setHTML(`<strong>${obu}</strong><p>${nazwa}</p>`).addTo(map);
        });
        map.on('mouseleave', 'points', function () {
          map.getCanvas().style.cursor = '';
          popup.remove();
        });
        map.on('mouseenter', 'clusters', function () {
          map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', 'clusters', function () {
          map.getCanvas().style.cursor = '';
        });
        map.on('click', 'points', function (e) {
          const [feature] = e.features;
          const {properties, geometry: {coordinates}} = feature;
          map.easeTo({
            center: coordinates,
            zoom: 15
          });
          store.selectRecord(properties.ident);
        });
        map.on('click', 'clusters', function (e) {
          const [feature] = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
          });
          const {cluster_id: clusterId} = feature.properties;
          map.getSource('points').getClusterExpansionZoom(
            clusterId,
            function (err, zoom) {
              if (err) return;
              const {coordinates} = feature.geometry;
              map.easeTo({
                center: coordinates,
                zoom: zoom
              });
            }
          );
        });
      });
    },
    clearMap(){
      this.map.remove();
    },
    refresh() {
      this.ts = Date.now();
    },
    async getDevices() {
      this.loading = true;
      const urzadzenia = await get(this.refUrzadzenia);
      this.urzadzenia = [];
      urzadzenia.forEach(urzadzenie => {
        this.urzadzenia.push({ident: urzadzenie.key, ...urzadzenie.val()});
      });
      this.loading = false;
    }
  }
}
</script>

<style scoped>
@import '~mapbox-gl/dist/mapbox-gl.css';
</style>