<template>
  <!-- Main content -->
  <div class="flex-1 flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
      <!-- Primary column -->
      <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
        <div class="py-6 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="pb-5 sm:flex sm:items-center sm:justify-between">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Urządzenia OBU
            </h3>
            <div class="mt-3 sm:mt-0 sm:ml-4">
              <button @click="addDevice" type="button" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Dodaj urządzenie OBU
              </button>
            </div>
          </div>
          <h1 id="primary-heading" class="sr-only">Urządzenia OBU</h1>
          <OBUUrzadzenia v-if="isUserUid" :user="user"></OBUUrzadzenia>
        </div>
      </section>
    </main>

    <!-- Secondary column (hidden on smaller screens) -->
    <OBUUrzadzenieDodaj v-if="showAddDevice" :user="user" ></OBUUrzadzenieDodaj>
    <OBUUrzadzenieEdytuj v-if="showEditDevice" :user="user" :urzadzenie="sharedState.device" ></OBUUrzadzenieEdytuj>
  </div>
</template>

<script>

import app from "@/firebase";
import store from "@/store";
import OBUUrzadzenia from '@/components/OBUUrzadzenia'
import OBUUrzadzenieDodaj from "@/components/OBUUrzadzenieDodaj";
import OBUUrzadzenieEdytuj from "@/components/OBUUrzadzenieEdytuj";

export default {
  name: 'OBU',
  props: ['add'],
  components: {
    OBUUrzadzenieDodaj,
    OBUUrzadzenieEdytuj,
    OBUUrzadzenia
  },
  data() {
    return {
      sharedState: store.state,
      user: app.auth.currentUser
    }
  },
  methods: {
    addDevice() {
      store.clearDevice()
      store.setOpDevice('ADD');
    },
  },
  computed: {
    isUserUid() {
      return  this.user != null && this.user.uid !== '';
    },
    showAddDevice() {
      return this.sharedState.opDevice === 'ADD';
    },
    showEditDevice() {
      return this.sharedState.opDevice === 'UPDATE' && this.sharedState.device != null;
    }
  }
}
</script>

<style scoped>

</style>