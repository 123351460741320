<template>
  <aside class="hidden w-96 border-l border-gray-200 overflow-y-auto lg:block py-6 sm:px-6 lg:px-8">
    <TransitionRoot
        appear
        :show="!loadingSaldo"
        enter="transition-opacity duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
      <div class="w-full shadow p-2 mx-auto bg-white rounded-2xl">
        <div class="bg-white px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ info.urzadzenie.obu }}
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            {{ info.urzadzenie.nazwa }} - saldo e-TOLL.
          </p>
        </div>
        <PanelSaldoInfo v-for="(saldoData, index) in saldoData" :key="index" :index="index" :saldoData="saldoData"/>
      </div>
    </TransitionRoot>
  </aside>
</template>
<script>

import {ref} from 'vue'
import {TransitionRoot} from "@headlessui/vue";
import {ref as fbRef, onValue, query, startAt, orderByPriority, off} from 'firebase/database';

import app from '@/firebase';
import PanelSaldoInfo from "@/components/PanelSaldoInfo";

let refSaldoData = null;

export default {
  name: 'PanelSaldo',
  props: ['info'],
  components: {
    TransitionRoot,
    PanelSaldoInfo
  },
  setup() {

    const loadingSaldo = ref(true);
    const ts = Date.now() - 3600000 * 24;

    const saldoDesc = {
      'UNKNOWN': {
        stan: 'BRAKDANYCH',
        opis: 'Brak danych',
        priorytet: 0,
        class: {
          'text-gray-800': true
        }
      },
      'ZEROBALANCE' : {
        stan: 'ZEROWEZALDO',
        opis: 'Zerowe saldo',
        priorytet: 2,
        class: {
          'text-red-800': true,
          'bg-red-100': true,
          'hover:bg-red-200': true
        }

      },
      'LOWBALANCE': {
        stan: 'NISKISTANKONTA',
        opis: 'Niski stan konta',
        priorytet: 2,
        class: {
          'text-red-800': true,
          'bg-red-100': true,
          'hover:bg-red-200': true
        }
      }
    };

    return {
      ts,
      loadingSaldo,
      saldoDesc
    }
  },
  watch: {
    'info.ident': function () {
      this.getSaldoData();
    }
  },
  data() {
    return {
      saldoData: []
    }
  },
  mounted() {
    this.getSaldoData();
  },
  unmounted() {
    off(refSaldoData);
  },
  methods: {
    getSaldoData() {
      this.loadingSaldo = true;
      if (refSaldoData) {
        off(refSaldoData);
      }
      refSaldoData = fbRef(app.database, `urzadzenie/${this.info.ident}/saldo`);
      onValue(query(refSaldoData, startAt(this.ts), orderByPriority()), data => {
        this.saldoData = [];
        data.forEach(data => {
          const value = data.val();
          const saldoDesc = this.saldoDesc[value.pr.toUpperCase()] || this.saldoDesc['UNKNOWN'];
          this.saldoData.push({typ: 'SALDO', ...value, ...saldoDesc});
        });
        this.saldoData = this.saldoData.sort((a, b) => a.ws < b.ws);
        this.loadingSaldo = false;
      })
    }
  }
}
</script>

<style scoped>

</style>