<template>
  <aside class="hidden w-96 border-l border-gray-200 overflow-y-auto lg:block py-6 sm:px-6 lg:px-8">
    <TransitionRoot
        appear
        :show="true"
        enter="transition-opacity duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <form action="#" method="POST" @submit.prevent="updateDevice">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">Edytuj urządzenie</h3>
                <p class="mt-1 text-sm text-gray-500">Edytuj dane urządzenia OBU.</p>
              </div>

              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-6">
                  <label for="obu" class="block text-sm font-medium text-gray-700">Numer OBU</label>
                  <input type="text" name="obu" id="obu" required disabled v-model="temp.obu" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>

                <div class="col-span-6">
                  <label for="pin" class="block text-sm font-medium text-gray-700">PIN OBU</label>
                  <input type="text" name="obu" id="pin" required v-model="temp.pin" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>

                <div class="col-span-6">
                  <label for="nazwa" class="block text-sm font-medium text-gray-700">Nazwa</label>
                  <input type="text" name="obu" id="nazwa" required v-model="temp.nazwa" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>

                <div class="col-span-6 relative flex items-start">
                  <div class="flex items-center h-5">
                    <input id="etoll" name="etoll" type="checkbox" :disabled="!isAdmin" v-model="temp.etoll" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="etoll" class="font-medium text-gray-700">e-TOLL</label>
                    <p class="text-gray-500">Przekazywanie danych do systemu e-TOLL.</p>
                  </div>
                </div>

                <div class="col-span-6 relative flex items-start">
                  <div class="flex items-center h-5">
                    <input id="sent" name="sent" type="checkbox" :disabled="!isAdmin" v-model="temp.sent" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="etoll" class="font-medium text-gray-700">SENT-GEO</label>
                    <p class="text-gray-500">Przekazywanie danych do systemu SENT-GEO.</p>
                  </div>
                </div>

              </div>

            </div>
            <div class="flex justify-end px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button @click="close" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Anuluj
              </button>
              <button type="submit" :disabled="loadingDevice" class="ml-3 bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <svg v-if="loadingDevice" class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Zapisz
              </button>

            </div>
          </div>
        </form>
      </div>
    </TransitionRoot>
  </aside>
</template>

<script>
import {ref} from 'vue'
import {TransitionRoot} from '@headlessui/vue';
import {httpsCallable} from 'firebase/functions';

import app from '@/firebase';
import store from '@/store';

export default {
  name: 'OBUUrzadzenieEdytuj',
  props: ['user', 'urzadzenie'],
  components: {
    TransitionRoot,
  },
  setup(props) {
    const loadingDevice = ref(false);
    const isAdmin = ref(false);
    props.user.getIdTokenResult().then((idTokenResult) => {
      isAdmin.value = idTokenResult.claims && idTokenResult.claims.admin;
    });
    return {
      loadingDevice,
      isAdmin
    }
  },
  watch: {
    'urzadzenie.ident': function () {
      this.temp = {
        ident: this.urzadzenie.ident,
        obu: this.urzadzenie.obu,
        pin: this.urzadzenie.pin,
        nazwa: this.urzadzenie.nazwa,
        etoll: this.urzadzenie.etoll,
        sent: this.urzadzenie.sent,
        alerty: this.urzadzenie.alerty
      };
    }
  },
  data() {
    return {
      temp: {
        ident: this.urzadzenie.ident,
        obu: this.urzadzenie.obu,
        pin: this.urzadzenie.pin,
        nazwa: this.urzadzenie.nazwa,
        etoll: this.urzadzenie.etoll,
        sent: this.urzadzenie.sent,
        alerty: this.urzadzenie.alerty
      }
    }
  },
  methods: {
    updateDevice() {
      this.loadingDevice = true;
      const updateDevice = httpsCallable(app.functions, 'updateDevice');
      updateDevice(this.temp).then((result) => {
        console.log(result);
        this.loadingDevice = false;
        store.clearOpDevice();
        store.clearDevice();
        store.refresh();
      });
    },
    close() {
      store.clearOpDevice();
      store.clearDevice();
    }
  }
}
</script>

<style scoped>

</style>