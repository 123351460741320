<template>
  <Disclosure v-slot="{open}" as="div" :class="{ 'mt-2':  index !== 0}">
    <DisclosureButton
        class="flex w-full px-4 py-2 text-sm font-normal text-left bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
    >
      <CheckCircleIcon v-if="saldoData.priorytet === OK" class="h-5 w-5 text-green-400" aria-hidden="true" />
      <ExclamationIcon v-if="saldoData.priorytet === WARNING" class="h-5 w-5 text-yellow-400" aria-hidden="true" />
      <XCircleIcon v-if="saldoData.priorytet === DANGER" class="h-5 w-5 text-red-400" aria-hidden="true" />
      <span class="truncate text-left flex-1 px-4">{{ tSys }} {{ saldoData.opis }}</span>
      <ChevronRightIcon
          :class="open ? 'transform rotate-90' : ''"
          class="w-5 h-5 text-right"
      />
    </DisclosureButton>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel class="px-2 pt-4 pb-2 text-xs font-medium text-gray-500">
      <div class="flex w-full px-4 py-2 text-left">
        <ClockIcon class="w-4 h-4 text-gray-400"/>
        <div class="w-full px-2">{{tSysDetail}}</div>
      </div>
      <div class="flex w-full px-4 py-2 text-left">
        <CashIcon class="w-4 h-4 text-gray-400"/>
        <div class="w-full px-2">{{saldoData.balance}}</div>
      </div>
      <div class="flex w-full px-4 py-2 text-left">
        <InformationCircleIcon class="w-4 h-4 text-gray-400"/>
        <div class="w-full px-2">{{saldoData.opis}}</div>
      </div>
    </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from "@headlessui/vue";
import {
  ChevronRightIcon,
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
  ClockIcon,
  InformationCircleIcon,
  CashIcon
} from "@heroicons/vue/solid";

const OK = 0;
const WARNING = 1;
const DANGER = 2;

export default {
  name: "PanelSaldoInfo",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
    CheckCircleIcon,
    ExclamationIcon,
    XCircleIcon,
    ClockIcon,
    InformationCircleIcon,
    CashIcon
  },
  props: ['index', 'saldoData'],
  setup() {

  },
  computed: {
    tSys(){
      return this.formatTime(this.saldoData.tsys);
    },
    tSysDetail() {
      return `${this.formatDate(this.saldoData.tsys)} ${this.formatTime(this.saldoData.tsys)}`;
    }
  },
  methods: {
    formatDate(milliseconds){
      return new Date(milliseconds).toLocaleDateString();
    },
    formatTime(milliseconds){
      return new Date(milliseconds).toLocaleTimeString().substr(0, 5);
    }
  },
  created() {
    this.OK = OK;
    this.WARNING = WARNING;
    this.DANGER = DANGER;
  },
}
</script>

<style scoped>

</style>