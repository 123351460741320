const OK = 0;
const WARNING = 1;
const DANGER = 2;

const IGNITION_OFF = 0;
const IGNITION_ON = 1;
const POLAND = 616;
const POLAND_OUTSIDE = 0;

const data = {
  OK: OK,
  WARNING: WARNING,
  DANGER: DANGER,
  IGNITION_OFF: IGNITION_OFF,
  IGNITION_ON: IGNITION_ON,
  POLAND: POLAND,
  POLAND_OUTSIDE: POLAND_OUTSIDE,

  statusDesc: {
    'UNKNOWN': {
      stan: 'BRAKDANYCH',
      opis: 'Brak danych',
      class: {
        'text-gray-800': true
      }
    },
    'ACTIVE': {
      stan: 'AKTYWNY',
      opis: 'Aktywny',
      class: {
        'text-green-800': true,
        'bg-green-100': true,
        'hover:bg-green-200': true
      }
    },
    'WARNING': {
      stan: 'OSTRZEZENIE',
      opis: 'Ostrzeżenie',
      class: {
        'text-yellow-800': true,
        'bg-yellow-100': true,
        'hover:bg-yellow-200': true
      }
    },
    'ALARM': {
      stan: 'ALARM',
      opis: 'Alarm',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    'POLAND_OUTSIDE': {
      stan: 'POZAOBSZAREM',
      opis: 'Poza obszarem',
      class: {
        'text-gray-800': true,
        'bg-gray-100': true,
        'hover:bg-gray-200': true
      }
    },
    'INACTIVE': {
      stan: 'NIEAKTYWNY',
      opis: 'Niekatywny',
      class: {
        'text-gray-800': true,
        'bg-gray-100': true,
        'hover:bg-gray-200': true
      }
    },
    'PLUGOUT': {
      stan: 'WYLACZONY',
      opis: 'Wyłączony',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    }
  },
  saldoDesc: {
    'UNKNOWN': {
      stan: 'BRAKDANYCH',
      opis: 'Brak danych',
      class: {
        'text-gray-800': true
      }
    },
    'ZEROBALANCE': {
      stan: 'ZEROWEZALDO',
      opis: 'Zerowe saldo',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    'LOWBALANCE': {
      stan: 'NISKISTANKONTA',
      opis: 'Niski stan konta',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    }
  },
  alertDesc: {
    'UNKNOWN': {
      priorytet: OK,
      opis: 'Brak opisu',
      class: {
        'text-gray-800': true
      }
    },
    'EMPTY': {
      priorytet: OK,
      opis: 'Brak aktywnych alertów',
      class: {
        'text-green-800': true,
        'bg-green-100': true,
        'hover:bg-green-200': true
      }
    },
    '1': {
      priorytet: DANGER,
      opis: 'Brak sygnału GPS',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '10': {
      priorytet: DANGER,
      opis: 'Brak zasilania',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '100': {
      priorytet: WARNING,
      opis: 'Niska jakość sygnału GPS (EPE X)',
      class: {
        'text-yellow-800': true,
        'bg-yellow-100': true,
        'hover:bg-yellow-200': true
      }
    },
    '1000': {
      priorytet: WARNING,
      opis: 'Niska jakość sygnału GPS (EPE Y)',
      class: {
        'text-yellow-800': true,
        'bg-yellow-100': true,
        'hover:bg-yellow-200': true
      }
    }
  },
  etollAlertDesc: {
    'UNKNOWN': {
      priorytet: OK,
      opis: 'Brak opisu',
      class: {
        'text-gray-800': true
      }
    },
    '0': {
      priorytet: OK,
      opis: 'Brak aktywnych alertów',
      class: {
        'text-green-800': true,
        'bg-green-100': true,
        'hover:bg-green-200': true
      }
    },
    '1': {
      priorytet: DANGER,
      opis: 'Błąd 500 interfejsu e-TOLL',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '10': {
      priorytet: DANGER,
      opis: 'Błąd 503 interfejsu e-TOLL',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '100': {
      priorytet: DANGER,
      opis: 'Inny błąd interfejsu e-TOLL',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    }
  },
  sentAlertDesc: {
    'UNKNOWN': {
      priorytet: OK,
      opis: 'Brak opisu',
      class: {
        'text-gray-800': true
      }
    },
    '0': {
      priorytet: OK,
      opis: 'Brak aktywnych alertów',
      class: {
        'text-green-800': true,
        'bg-green-100': true,
        'hover:bg-green-200': true
      }
    },
    '1': {
      priorytet: DANGER,
      opis: 'Błąd 500 interfejsu SENT-GEO',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '10': {
      priorytet: DANGER,
      opis: 'Błąd 503 interfejsu SENT-GEO',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '100': {
      priorytet: DANGER,
      opis: 'Inny błąd interfejsu SENT-GEO',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    }
  },
  odczytCheckDesc: {
    'UNKNOWN': {
      priorytet: OK,
      opis: 'Brak opisu'
    },
    '5': {
      priorytet: WARNING,
      opis: 'Brak danych z OBU od 5 minut',
      class: {
        'text-yellow-800': true,
        'bg-yellow-100': true,
        'hover:bg-yellow-200': true
      }
    },
    '10': {
      priorytet: DANGER,
      opis: 'Brak danych z OBU od 10 minut',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '15': {
      priorytet: DANGER,
      opis: 'Brak danych z OBU od 15 minut',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '1440': {
      priorytet: DANGER,
      opis: 'Brak danych z OBU od 24 godzin',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '2880': {
      priorytet: DANGER,
      opis: 'Brak danych z OBU od 48 godzin',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '4320': {
      priorytet: DANGER,
      opis: 'Brak danych z OBU od 72 godzin',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    }
  },
  etollCheckDesc: {
    'UNKNOWN': {
      priorytet: OK,
      opis: 'Brak opisu'
    },
    '5': {
      priorytet: WARNING,
      opis: 'Brak danych z e-TOLL od 5 minut',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '10': {
      priorytet: DANGER,
      opis: 'Brak danych z e-TOLL od 10 minut',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '15': {
      priorytet: DANGER,
      opis: 'Brak danych z e-TOLL od 15 minut',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '1440': {
      priorytet: DANGER,
      opis: 'Brak danych z e-TOLL od 24 godzin',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '2880': {
      priorytet: DANGER,
      opis: 'Brak danych z e-TOLL od 48 godzin',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    },
    '4320': {
      priorytet: DANGER,
      opis: 'Brak danych z e-TOLL od 72 godzin',
      class: {
        'text-red-800': true,
        'bg-red-100': true,
        'hover:bg-red-200': true
      }
    }
  },
  sentCheckDesc: {
    'UNKNOWN': {
      priorytet: OK,
      opis: 'Brak opisu'
    },
    '5': {
      priorytet: WARNING,
      opis: 'Brak danych z SENT-GEO od 5 minut'
    },
    '10': {
      priorytet: DANGER,
      opis: 'Brak danych z SENT-GEO od 10 minut'
    },
    '15': {
      priorytet: DANGER,
      opis: 'Brak danych z SENT-GEO od 15 minut'
    },
    '1440': {
      priorytet: DANGER,
      opis: 'Brak danych z SENT-GEO od 24 godzin'
    },
    '2880': {
      priorytet: DANGER,
      opis: 'Brak danych z SENT-GEO od 48 godzin'
    },
    '4320': {
      priorytet: DANGER,
      opis: 'Brak danych z SENT-GEO od 72 godzin'
    }
  },
}

export default data;