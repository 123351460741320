<template>
  <aside class="hidden w-96 border-l border-gray-200 overflow-y-auto lg:block py-6 sm:px-6 lg:px-8">
    <TransitionRoot
        appear
        :show="!loadingAlert"
        enter="transition-opacity duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <form action="#" method="GET" @submit.prevent="addDevice">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">{{ info.urzadzenie.obu }}</h3>
                <p class="mt-1 text-sm text-gray-500">{{ info.urzadzenie.nazwa }} - lista alertów z 24H.</p>
              </div>

              <PanelAlertInfo v-for="(alertData, index) in alertData" :key="index" :index="index"
                              :alertData="alertData"/>

            </div>
          </div>
        </form>
      </div>

    </TransitionRoot>
  </aside>
</template>

<script>
import {ref} from 'vue'
import {TransitionRoot} from "@headlessui/vue";
import {ref as fbRef, onValue, query, startAt, orderByPriority, off} from 'firebase/database';

import app from '@/firebase';
import data from '@/const';
import PanelAlertInfo from "@/components/PanelAlertInfo";

let refAlert = null;

export default {
  name: 'PanelAlert',
  components: {
    TransitionRoot,
    PanelAlertInfo
  },
  props: ['info'],
  setup() {

    const ts = Date.now() - 3600000 * 24;
    const loadingAlert = ref(true);

    const alertDesc = data.alertDesc;
    const etollAlertDesc = data.etollAlertDesc;
    const sentAlertDesc = data.sentAlertDesc;
    const odczytCheckDesc = data.odczytCheckDesc;
    const etollCheckDesc = data.etollCheckDesc;
    const sentCheckDesc = data.sentCheckDesc;

    return {
      ts,
      alertDesc,
      etollAlertDesc,
      sentAlertDesc,
      odczytCheckDesc,
      etollCheckDesc,
      sentCheckDesc,
      loadingAlert
    }
  },
  watch: {
    'info.ident': function () {
      this.getAlertData();
    }
  },
  data() {
    return {
      alert: []
    }
  },
  computed: {
    alertData() {
      return this.alert.slice(0).sort((a, b) => a.tmax < b.tmax);
    }
  },
  mounted() {
    this.getAlertData();
  },
  unmounted() {
    if (refAlert) {
      off(refAlert);
    }
  },
  methods: {
    getAlertData() {
      this.loadingAlert = true;
      if (refAlert) {
        off(refAlert);
      }
      refAlert = fbRef(app.database, `/urzadzenie/${this.info.ident}/alert`);
      onValue(query(refAlert, startAt(this.ts), orderByPriority()), data => {
        this.alert = [];
        data.forEach(data => {
          const value = data.val();
          if (value.type === 'ODCZYT') {
            for(const idx of [1, 2, 3, 4]) {
              const digit = this.getDigit(value.code, idx);
              if (digit > 0) {
                const code =  Math.pow(10, idx - 1);
                const alertDesc = this.alertDesc[`${code}`] || this.alertDesc['UNKNOWN'];
                this.alert.push({typ: 'STATS', ...value, ...alertDesc});
              }
            }
          }
          if (value.type === 'ETOLL') {
            for(const idx of [1, 2, 3]) {
              const digit = this.getDigit(value.code, idx);
              if (digit > 0) {
                const code =  Math.pow(10, idx - 1);
                const alertDesc = this.etollAlertDesc[`${code}`] || this.etollAlertDesc['UNKNOWN'];
                this.alert.push({typ: 'STATS', ...value, ...alertDesc});
              }
            }
          }
          if (value.type === 'SENT') {
            for(const idx of [1, 2, 3]) {
              const digit = this.getDigit(value.code, idx);
              if (digit > 0) {
                const code =  Math.pow(10, idx - 1);
                const alertDesc = this.sentAlertDesc[`${code}`] || this.sentAlertDesc['UNKNOWN'];
                this.alert.push({typ: 'STATS', ...value, ...alertDesc});
              }
            }
          }
          if (value.type === 'ODCZYT_ALERT') {
            const alertDesc = this.odczytCheckDesc[`${value.code}`] || this.odczytCheckDesc['UNKNOWN'];
            this.alert.push({typ: 'CHECK', ...value, ...alertDesc});
          }

          if (value.type === 'ETOLL_ALERT') {
            const alertDesc = this.etollCheckDesc[`${value.code}`] || this.etollCheckDesc['UNKNOWN'];
            this.alert.push({typ: 'CHECK', ...value, ...alertDesc});
          }

          if (value.type === 'SENT_ALERT') {
            const alertDesc = this.sentCheckDesc[`${value.code}`] || this.sentCheckDesc['UNKNOWN'];
            this.alert.push({typ: 'CHECK', ...value, ...alertDesc});
          }
        });
        this.loadingAlert = false;
      });
    },
    getDigit(number, n) {
      return Math.floor((number / Math.pow(10, n - 1)) % 10);
    }
  }
}
</script>

<style scoped>

</style>