import {reactive} from 'vue';

const store = {
  debug: true,

  state: reactive({
    device: null,
    deviceInfo: null,
    deviceAlert: null,
    refresh: 0,
    selectedMenu: 0,
    search: '',
    opDevice: '',
    record: '',
    records: {},
    refreshRecords: 0
  }),

  setDevice(device) {
    if (this.debug) {
      console.log(`setDevice triggered ${device}`);
    }

    this.state.device = device;
  },
  clearDevice() {
    if (this.debug) {
      console.log('clearDevice triggered')
    }

    this.state.device = null;
  },

  setDeviceInfo(deviceInfo) {
    if (this.debug) {
      console.log(`setDeviceInfo triggered ${deviceInfo}`);
    }

    this.state.deviceInfo = deviceInfo;
  },
  clearDeviceInfo() {
    if (this.debug) {
      console.log('clearDeviceInfo triggered')
    }

    this.state.deviceInfo = null;
  },

  setDeviceAlert(deviceAlert) {
    if (this.debug) {
      console.log(`setDeviceAlert triggered ${deviceAlert}`);
    }

    this.state.deviceAlert = deviceAlert;
  },
  clearDeviceAlert() {
    if (this.debug) {
      console.log('clearDeviceAlert triggered')
    }

    this.state.deviceAlert = null;
  },

  setSearch(search) {
    if (this.debug) {
      console.log(`setSearch triggered ${search}`);
    }

    this.state.search = search;
  },
  clearSearch() {
    if (this.debug) {
      console.log('clearSearch triggered')
    }

    this.state.search = null;
  },
  refresh(){
    if (this.debug) {
      console.log(`refresh triggered`);
    }

    this.state.refresh = Date.now();
  },
  setOpDevice(op) {
    if (this.debug) {
      console.log(`opDevice triggered`);
    }

    this.state.opDevice = op;
  },
  clearOpDevice() {
    if (this.debug) {
      console.log(`clearOpDevice triggered`);
    }

    this.state.opDevice = '';
  },
  setRecord(record){
    if (this.debug) {
      console.log(`setRecord triggered`);
    }
    if (record && record.ident) {
      this.state.records[record.ident] = record;
      this.state.refreshRecords = Date.now();
    }
  },
  clearRecord(record) {
    if (this.debug) {
      console.log(`clearRecord triggered`);
    }
    if (record && record.ident) {
      delete this.state.records[record.ident];
      this.state.refreshRecords = Date.now();
    }
  },
  selectRecord(record){
    if (this.debug) {
      console.log(`selectRecord triggered`);
    }

    this.state.record = record;
  },
  unselectRecord(){
    if (this.debug) {
      console.log(`unselectRecord triggered`);
    }

    this.state.record = '';
  },
  refreshRecords(){
    if (this.debug) {
      console.log(`refreshRecords triggered`);
    }
    this.state.refreshRecords = Date.now();
  }
}

export default store;