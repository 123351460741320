<template>
  <Disclosure v-slot="{open}" as="div" :class="{ 'mt-2':  index !== 0}">
    <DisclosureButton
        class="flex justify-between w-full px-4 py-2 text-sm font-normal text-left bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
    >
      <CheckCircleIcon v-if="statStatus === OK" class="h-5 w-5 text-green-400" aria-hidden="true"/>
      <ExclamationIcon v-if="statStatus === WARNING" class="h-5 w-5 text-yellow-400" aria-hidden="true"/>
      <XCircleIcon v-if="statStatus=== DANGER" class="h-5 w-5 text-red-400" aria-hidden="true"/>
      <span>{{ windowDate }} {{ windowStart }} - {{ windowEnd }}</span>
      <ChevronRightIcon
          :class="open ? 'transform rotate-90' : ''"
          class="w-5 h-5"
      />
    </DisclosureButton>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel class="px-2 pt-4 pb-2 text-xs font-medium text-gray-500">
        <div class="flex w-full px-4 py-2">
          <button type="button" @click="showHistogram = !showHistogram" class="relative">
            <LocationMarkerIcon class="w-4 h-4 absolute animate-ping opacity-75" :class="gpsOfflineClass"/>
            <LocationMarkerIcon class="w-4 h-4" :class="gpsOfflineClass"/>
          </button>
          <div class="w-full text-left px-2">GPS</div>
          <div class="text-right whitespace-nowrap">{{ gpsOffline }}% ({{ statData.c - statData.gpso }} /
            {{ statData.c }})
          </div>
        </div>
        <div v-if="showHistogram" class="flex justify-center w-full px-4 py-2 text-left">
          <figure>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="chart" width="210" height="16"
                 aria-labelledby="title" role="img">
              <title id="title">Histogram liczby satelit GNSS</title>
              <g class="bar">
                <text x="0" y="14">0</text>
              </g>
              <g v-for="(val, key) in histGPS" :key="key" class="bar">
                <rect width="4" :height="Math.round(val / 2) + 1" :y="15 - Math.round(val / 2) "
                      :x="10 + key * 5"></rect>
                <title>{{key}}-{{val}}</title>
              </g>
              <g class="bar">
                <text x="195" y="14">35</text>
              </g>
            </svg>
          </figure>
        </div>
        <div v-if="showHistogram" class="flex justify-center w-full px-4 py-2 text-left">
          <figure>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="chart" width="80" height="16"
                 aria-labelledby="title" role="img">
              <title id="title">Histogram EPE X [m]</title>
              <g class="bar">
                <text x="0" y="14">0</text>
              </g>
              <g v-for="(val, key) in histEPEX" :key="key" class="bar">
                <rect width="4" :height="Math.round(val / 2) + 1" :y="15 - Math.round(val / 2) "
                      :x="10 + key * 5"></rect>
                <title>{{key}} [m]-{{val}}</title>
              </g>
              <g class="bar">
                <text x="65" y="14">9</text>
              </g>
            </svg>
          </figure>
          <figure>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="chart" width="80" height="16"
                 aria-labelledby="title" role="img">
              <title id="title">Histogram EPE Y [m]</title>
              <g class="bar">
                <text x="0" y="14">0</text>
              </g>
              <g v-for="(val, key) in histEPEY" :key="key" class="bar">
                <rect width="4" :height="Math.round(val / 2) + 1" :y="15 - Math.round(val / 2) "
                      :x="10 + key * 5"></rect>
                <title>{{key}} [m]-{{val}}</title>
              </g>
              <g class="bar">
                <text x="65" y="14">9</text>
              </g>
            </svg>
          </figure>
        </div>
        <div class="flex w-full px-4 py-2">
          <div>
            <LightningBoltIcon class="w-4 h-4" :class="plugoutClass"/>
          </div>
          <div class="w-full text-left px-2">Zasilanie</div>
          <div class="text-right whitespace-nowrap">{{ plugOut }}% ({{ statData.c - statData.po }} / {{ statData.c }})
          </div>
        </div>
        <div class="flex w-full px-4 py-2">
          <div>
            <StatusOnlineIcon class="w-4 h-4" :class="gsmOfflineClass"/>
          </div>
          <div class="w-full text-left px-2">GSM</div>
          <div class="text-right whitespace-nowrap">{{ gsmOffline }}% ({{ statData.c - statData.gsmo }} /
            {{ statData.c }})
          </div>
        </div>
        <div class="flex w-full px-4 py-2 text-left">
          <div>
            <BanIcon class="w-4 h-4" :class="jammingClass"/>
          </div>
          <div class="w-full text-left px-2">Jamming</div>
          <div class="text-right whitespace-nowrap">{{ jamming }}% ({{ statData.jm }} / {{ statData.c }})</div>
        </div>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script>
import {ref} from 'vue'

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from "@headlessui/vue";
import {
  ChevronRightIcon,
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
  LocationMarkerIcon,
  LightningBoltIcon,
  StatusOnlineIcon,
  BanIcon
} from "@heroicons/vue/solid";

const OK = 0;
const WARNING = 1;
const DANGER = 2;

export default {
  name: "PanelInfoStats",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
    CheckCircleIcon,
    ExclamationIcon,
    XCircleIcon,
    LocationMarkerIcon,
    LightningBoltIcon,
    StatusOnlineIcon,
    BanIcon
  },
  props: ['index', 'statData'],
  setup() {
    const showHistogram = ref(false);

    return {
      showHistogram
    }
  },
  computed: {
    statStatus() {
      if (this.statData.po + this.statData.gpso + this.statData.gsmo + this.statData.gsml + this.statData.ex + this.statData.ey + this.statData.jm === 0) {
        return OK;
      } else if (this.statData.po + this.statData.gpso > 0) {
        return DANGER;
      } else if (this.statData.ex + this.statData.ey + this.statData.jm > 0) {
        return WARNING;
      }
      return OK;
    },
    windowDate() {
      if (Date.now() - this.statData.ws > 24 * 3600000) {
        return new Date(this.statData.ws).toLocaleDateString();
      } else {
        return null;
      }
    },
    windowStart() {
      return this.formatTime(this.statData.ws);
    },
    windowEnd() {
      return this.formatTime(this.statData.we);
    },
    gpsOfflineClass() {
      if (this.statData.gpso > 0) {
        return 'text-red-400';
      } else if (this.statData.ex > 0 || this.statData.ey > 0) {
        return 'text-yellow-400';
      } else {
        return 'text-green-400';
      }
    },
    histGPS() {
      const histGPS = {};
      for (const key of Array(36).keys()) {
        histGPS[key] = this.statData.gpsh[key] || 0;
      }
      return histGPS;
    },
    histEPEX() {
      const histEPEX = {};
      for (const key of Array(10).keys()) {
        histEPEX[key] = this.statData.exh[key] || 0;
      }
      return histEPEX;
    },
    histEPEY() {
      const histEPEY = {};
      for (const key of Array(10).keys()) {
        histEPEY[key] = this.statData.eyh[key] || 0;
      }
      return histEPEY;
    },
    plugoutClass() {
      if (this.statData.po > 0) {
        return 'text-red-400'
      } else {
        return 'text-gray-400';
      }
    },
    gsmOfflineClass() {
      if (this.statData.gsmo > 0 || this.statData.gsml > 0) {
        return 'text-yellow-400'
      } else {
        return 'text-gray-400';
      }
    },
    jammingClass() {
      if (this.statData.jm > 0) {
        return 'text-red-400'
      } else {
        return 'text-gray-400';
      }
    },
    gpsOffline() {
      return Math.round(100 - this.statData.gpso / this.statData.c * 100);
    },
    plugOut() {
      return Math.round(100 - this.statData.po / this.statData.c * 100);
    },
    gsmOffline() {
      return Math.round(100 - this.statData.gsmo / this.statData.c * 100);
    },
    jamming() {
      return Math.round(this.statData.jm / this.statData.c * 100);
    }
  },
  methods: {
    formatDate(milliseconds) {
      return new Date(milliseconds).toLocaleDateString();
    },
    formatTime(milliseconds) {
      return new Date(milliseconds).toLocaleTimeString().substr(0, 5);
    }
  },
  created() {
    this.OK = OK;
    this.WARNING = WARNING;
    this.DANGER = DANGER;
  },
}
</script>

<style scoped>

.bar {
  fill: #aaa;
  transition: fill .3s ease;
  cursor: pointer;
  font-family: Helvetica, sans-serif;
}

.bar.text {
  fill: #555;
}

.chart:hover,
.chart:focus.bar {
  fill: #aaa;
}

.bar:hover,
.bar:focus {
  fill: red !important;
}

.bar.focus.text {
  fill: red;
}

</style>