<template>
  <li>
    <a href="#" @click.prevent="selectRecord" class="border-l-8 block hover:bg-gray-50"
       :class="selected ? 'border-indigo-500':''">
      <div class="px-4 py-4 sm:px-6">
        <div class="flex items-center justify-between">
          <div>
            <div class="text-sm text-gray-900">{{ urzadzenie.obu }}</div>
            <div class="text-xs text-gray-500">{{ urzadzenie.nazwa }}</div>
          </div>
          <div class="ml-2 text-xs text-gray-500">
            <div>
              {{ timestampDetail }}
            </div>
            <div>
              {{ sat }} {{ bat }}
            </div>
          </div>
          <div class="ml-2 text-xs text-gray-500">
            <div>
              {{ speed }}
            </div>
            <div>
              {{ mileage }}
            </div>
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>

import {ref} from 'vue';
import {ref as fbRef, onValue, off} from 'firebase/database';

import app from '@/firebase';
import store from '@/store';

export default {
  name: 'MapaUrzadzenie',
  components: {},
  props: ['urzadzenie', 'selected', 'ts'],
  setup(props) {

    const loading = ref(true);
    const refRec = fbRef(app.database, `urzadzenie/${props.urzadzenie.ident}/rec`);
    return {
      loading,
      refRec
    }
  },
  data() {
    return {
      sharedState: store.state,
      recData: {}
    }
  },
  mounted() {
    this.getRecData();
  },
  unmounted() {
    store.clearRecord(this.recData);
    off(this.refRec);
  },
  computed: {
    timestampDetail() {
      if (!this.recData) {
        return `Brak danych`;
      }
      return `${this.formatDate(this.recData.timestamp)} ${this.formatTime(this.recData.timestamp)}`;
    },
    speed() {
      if (!this.recData) {
        return null;
      }
      return `${this.recData.spd} [km/h]`
    },
    mileage() {
      if (!this.recData || !this.recData.data) {
        return null;
      }
      return `${Math.round(parseInt(this.recData.data['65']) / 1000)} [km]`
    },
    sat() {
      if (!this.recData) {
        return null;
      }
      return `Sat.: ${parseInt(this.recData.sat)}`;
    },
    bat() {
      if (!this.recData || !this.recData.data) {
        return null;
      }
      return `Bat.: ${(parseInt(this.recData.data['29']) / 1000).toFixed(1)} [V]`
    }
  },
  methods: {
    getRecData() {
      this.loading = true;
      onValue(this.refRec, data => {
        this.recData = data.val();
        const {obu, nazwa} = this.urzadzenie;
        store.setRecord({obu: obu, nazwa: nazwa, ...this.recData});
        this.loading = false;
      });
    },
    selectRecord() {
      store.selectRecord(this.urzadzenie.ident);
    },
    formatDate(milliseconds) {
      const date = new Date(milliseconds)
      date.setHours(0,0,0,0);
      const today = new Date(this.ts);
      today.setHours(0,0,0,0);
      const yesterday = new Date(new Date(this.ts).valueOf() - 24 * 3600 * 1000);
      yesterday.setHours(0,0,0,0);
      if (date.valueOf() === today.valueOf()){
        return 'Dzisiaj';
      } else if (date.valueOf() === yesterday.valueOf()) {
        return 'Wczoraj';
      }
      return new Date(milliseconds).toLocaleDateString();
    },
    formatTime(milliseconds) {
      return new Date(milliseconds).toLocaleTimeString().substr(0, 5);
    }
  }
}
</script>

<style scoped>

</style>