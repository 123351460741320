<template>
  <div class="h-screen bg-white overflow-hidden flex">
    <main class="overflow-y-auto max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
      <div class="space-y-6">
        <div>
          <h1 class="text-lg leading-6 font-medium text-gray-900">
            Ustawienia
          </h1>
        </div>

        <div>
          <label for="project_name" class="block text-sm font-medium text-gray-700">
            Użytkownik konta
          </label>
          <p class="text-sm mt-1 font-medium text-gray-900">
            {{user.email}}
          </p>
        </div>

        <div class="space-y-2">
          <div class="space-y-1">
            <label for="dodaj_email" class="block text-sm font-medium text-gray-700">
              Adresy email alertów
            </label>
            <p id="dodaj_email_pomoc" class="sr-only">Dodaj email do powiadomień</p>
            <form action="#" method="POST" @submit.prevent="addEmail">
              <div class="flex">
                <div class="flex-grow">
                  <input type="email" name="dodaj_email" id="dodaj_email" v-model="newEmail" required
                         class="block w-full shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm border-gray-300 rounded-md"
                         placeholder="Adres email" aria-describedby="dodaj_email_pomoc"/>
                </div>
                <span class="ml-3">
                  <button type="submit"
                          class="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500">
                    <PlusIcon class="-ml-2 mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
                    <span>Dodaj</span>
                  </button>
                </span>
              </div>
            </form>
          </div>

          <div class="flow-root mt-6">
            <ul class="divide-y divide-gray-200">
              <li v-for="email in emails" :key="email.id" class="py-4">
                <div class="flex items-center space-x-4">
                  <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-gray-900 truncate">
                      {{ email.email }}
                    </p>
                  </div>
                  <div>
                    <button type="button" @click="removeEmail(email)" class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                      Usuń
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>

  </div>
</template>

<script>

import {ref as fbRef, onValue, off} from 'firebase/database';
import {httpsCallable} from 'firebase/functions';

import {PlusIcon} from '@heroicons/vue/solid'

import app from '@/firebase';
import store from '@/store';

export default {
  components: {
    PlusIcon
  },
  name: 'Ustawienia',
  setup() {

    const refEmails = fbRef(app.database, `uzytkownik/${app.auth.currentUser.uid}/email`);

    return {
      refEmails
    }
  },
  data () {
    return {
      sharedState: store.state,
      user: app.auth.currentUser,
      emails: [],
      newEmail: ''
    }
  },
  mounted() {
    this.getEmails();
  },
  unmounted() {
    off(this.refEmails);
  },
  methods: {
    getEmails() {
      onValue(this.refEmails, data => {
        this.emails = [];
        data.forEach((email) => {
          this.emails.push({id: email.key, email: email.val()})
        });
      })
    },
    removeEmail(email) {
      const removeEmail = httpsCallable(app.functions, 'removeEmail');
      removeEmail({key: email.id}).then((result) => {
        console.log(result);
      });
    },
    addEmail() {
      const addEmail = httpsCallable(app.functions, 'addEmail');
      addEmail({email: this.newEmail}).then((result) => {
        console.log(result);
        this.newEmail = '';
      });
    }
  }
}
</script>

<style scoped>

</style>