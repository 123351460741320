<template>
  <Disclosure v-slot="{open}" as="div" :class="{ 'mt-2':  index !== 0}">
    <DisclosureButton
        class="flex text-right justify-between w-full px-4 py-2 text-sm font-normal text-left bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
    >
      <CheckCircleIcon v-if="SENTStatus === OK" class="h-5 w-5 text-green-400" aria-hidden="true"/>
      <XCircleIcon v-if="SENTStatus=== DANGER" class="h-5 w-5 text-red-400" aria-hidden="true"/>

      <span>{{windowDate}} {{ windowStart }} - {{ windowEnd }}</span>
      <ChevronRightIcon
          :class="open ? 'transform rotate-90' : ''"
          class="w-5 h-5"
      />
    </DisclosureButton>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel class="px-2 pt-4 pb-2 text-xs font-medium text-gray-500">
        <div class="flex w-full px-4 py-2">
          <div>
            <CheckCircleIcon class="w-4 h-4" :class="c200Class"/>
          </div>
          <div class="w-full text-left px-2">Status OK</div>
          <div class="text-right whitespace-nowrap">{{ c200 }}% ({{ SENTData.c200 }} / {{ SENTData.c }})</div>
        </div>
        <div class="flex w-full px-4 py-2 text-left">
          <div>
            <XCircleIcon class="w-4 h-4" :class="c500Class"/>
          </div>
          <div class="w-full text-left px-2">Błąd 500 SENT-GEO</div>
          <div class="text-right whitespace-nowrap">{{ c500 }}% ({{ SENTData.c500 }} / {{ SENTData.c }})</div>
        </div>
        <div class="flex w-full px-4 py-2 text-left">
          <div>
            <XCircleIcon class="w-4 h-4" :class="c503Class"/>
          </div>
          <div class="w-full text-left px-2">Błąd 503 SENT-GEO</div>
          <div class="text-right whitespace-nowrap">{{ c503 }}% ({{ SENTData.c503 }} / {{ SENTData.c }})</div>
        </div>
        <div class="flex w-full px-4 py-2 text-left">
          <div>
            <XCircleIcon class="w-4 h-4" :class="coClass"/>
          </div>
          <div class="w-full text-left px-2">Błąd inny SENT-GEO</div>
          <div class="text-right whitespace-nowrap">{{ co }}% ({{ SENTData.co }} / {{ SENTData.c }})</div>
        </div>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script>

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from "@headlessui/vue";
import {
  ChevronRightIcon,
  CheckCircleIcon,
  XCircleIcon
} from "@heroicons/vue/solid";

const OK = 0;
const WARNING = 1;
const DANGER = 2;

export default {
  name: "PanelSENTInfo",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
    CheckCircleIcon,
    XCircleIcon
  },
  props: ['index', 'SENTData'],
  setup() {

  },
  computed: {
    SENTStatus() {
      if (this.SENTData.c === this.SENTData.c200) {
        return OK;
      } else {
        return DANGER;
      }
    },
    windowDate() {
      if (Date.now() - this.SENTData.ws > 24 * 3600000) {
        return new Date(this.SENTData.ws).toLocaleDateString();
      } else {
        return null;
      }
    },
    windowStart() {
      return this.formatTime(this.SENTData.ws);
    },
    windowEnd() {
      return this.formatTime(this.SENTData.we);
    },
    c200() {
      return Math.round(this.SENTData.c200 / this.SENTData.c * 100);
    },
    c500() {
      return Math.round(this.SENTData.c500 / this.SENTData.c * 100);
    },
    c503() {
      return Math.round(this.SENTData.c503 / this.SENTData.c * 100);
    },
    co() {
      return Math.round(this.SENTData.co / this.SENTData.c * 100);
    },
    c200Class() {
      if (this.SENTData.c === this.SENTData.c200) {
        return 'text-green-400';
      } else {
        return 'text-red-400';
      }
    },
    c500Class() {
      if (this.SENTData.c500 === 0) {
        return 'text-gray-400';
      } else {
        return 'text-red-400';
      }
    },
    c503Class() {
      if (this.SENTData.c503 === 0) {
        return 'text-gray-400';
      } else {
        return 'text-red-400';
      }
    },
    coClass() {
      if (this.SENTData.co === 0) {
        return 'text-gray-400';
      } else {
        return 'text-red-400';
      }
    }
  },
  methods: {
    formatTime(milliseconds) {
      return new Date(milliseconds).toLocaleTimeString().substr(0, 5);
    }
  },
  created() {
    this.OK = OK;
    this.WARNING = WARNING;
    this.DANGER = DANGER;
  },
}
</script>

<style scoped>

</style>