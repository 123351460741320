<template>
  <TransitionRoot
    appear
    :show="urzadzenia.length > 0"
    enter="transition-opacity duration-1000"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-1000"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-md">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
        <tr>
          <th scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            OBU
          </th>
          <th scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
          <th scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            e-TOLL
          </th>
<!--          <th scope="col"-->
<!--              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">-->
<!--            Saldo-->
<!--          </th>-->
          <th scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            SENT-GEO
          </th>
          <th scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Alerty
          </th>
        </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">

        <PanelUrzadzenie v-for="urzadzenie in stronaUrzadzen" :key="urzadzenie.ident" :ts="ts"
                         :urzadzenie="urzadzenie"></PanelUrzadzenie>
        </tbody>
      </table>
      <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
           aria-label="Stronnicowanie">
        <div class="hidden sm:block">
          <p v-if="filtrUrzadzen.length" class="text-sm text-gray-700">
            Wyświetla
            <span class="font-medium">{{ (currentPage - 1) * pageSize + 1}}</span>
            do
            <span class="font-medium">{{ (currentPage - 1) * pageSize + stronaUrzadzen.length }}</span>
            z
            <span class="font-medium">{{ filtrUrzadzen.length }}</span>
            wyników
          </p>
          <p v-if="!filtrUrzadzen.length" class="text-sm text-gray-700">
            Brak wyników
          </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
          <a href="#" @click.prevent="prevPage" :disabled="firstPage"
             :class="{ 'opacity-25': firstPage }"
             class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Poprzedni
          </a>
          <a href="#" @click.prevent="nextPage" :disabled="lastPage"
             :class="{ 'opacity-25': lastPage }"
             class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Następny
          </a>
        </div>
      </nav>
    </div>
  </TransitionRoot>
</template>

<script>

import {ref} from 'vue';
import {TransitionRoot} from "@headlessui/vue";
import {ref as fbRef, get} from 'firebase/database';

import app from '@/firebase';
import store from '@/store';
import PanelUrzadzenie from "@/components/PanelUrzadzenie";

export default {
  name: 'PanelUrzadzenia',
  components: {PanelUrzadzenie, TransitionRoot},
  props: ['user'],
  data() {
    return {
      sharedState: store.state,
      urzadzenia: [],
      timer: {},
      ts: Date.now(),
      pageSize: 10,
      currentPage: 1
    }
  },
  setup(props) {

    const loading = ref(true);
    const refUrzadzenia = fbRef(app.database, `uzytkownik/${props.user.uid}/urzadzenie`);
    return {
      loading,
      refUrzadzenia
    }
  },
  mounted() {
    this.getDevices();
    this.timer = setInterval(this.refresh, 60 * 1000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  computed: {
    filtrUrzadzen() {
      if (!this.sharedState.search) {
        return this.urzadzenia;
      }
      const filter = value =>
        value.obu.toLowerCase().includes(this.sharedState.search.toLowerCase()) ||
        value.nazwa.toLowerCase().includes(this.sharedState.search.toLowerCase());
      return this.urzadzenia.filter(filter)

    },
    stronaUrzadzen(){
      return this.filtrUrzadzen
        .filter((row, index) => {
          let start = (this.currentPage - 1) * this.pageSize;
          let end = this.currentPage * this.pageSize;
          if (index >= start && index < end) return true;
        });
    },
    firstPage() {
      return this.currentPage === 1;
    },
    lastPage() {
      return (this.currentPage * this.pageSize) >= this.filtrUrzadzen.length;
    }
  },
  watch: {
    'sharedState.refresh': function () {
      this.getDevices();
    }
  },
  methods: {
    async getDevices() {
      this.loading = true;
      const urzadzenia = await get(this.refUrzadzenia);
      this.urzadzenia = [];
      urzadzenia.forEach(urzadzenie => {
        this.urzadzenia.push({ident: urzadzenie.key, ...urzadzenie.val()});
      });
      this.loading = false;
    },
    refresh() {
      this.ts = Date.now();
    },
    nextPage() {
      console.log(this.filtrUrzadzen.length);
      if ((this.currentPage * this.pageSize) < this.filtrUrzadzen.length) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    }
  }
}
</script>

<style scoped>

</style>